@import "node_modules/@swimlane/ngx-datatable/index.css";
@import "node_modules/@swimlane/ngx-datatable/themes/material.css";
@import "node_modules/@swimlane/ngx-datatable/assets/icons.css";
@import "@ctrl/ngx-emoji-mart/picker";

* {
  font-family: "Inter", sans-serif;
  letter-spacing: 0px !important;
}

html {
  height: 100%;
  overflow: hidden;
  position: relative;
  letter-spacing: -10x !important;
}
body {
  height: 100%;
  overflow: hidden;
  position: relative;
  margin: 0;
}

:root {
  --app-container: #f7f5f1;
  --app-beachy: #e7d1b5;
  --app-beachy-light: #f8e8d4;
  --app-timber: #344734;
  --app-text-grey: #9f9f9f;
  --app-light-grey: #c9c9c9;
  --app-lightest-grey: #ececec;
  --app-lighter-grey: #5e4c4c;
  --app-background: #f5f5f5;
  --app-wiskey: #f1a638;
}

.dialog-body {
  color: black;
  font-size: 14px;
}

.detail-container {
  background-color: var(--app-container);
  border-radius: 10px;
  padding: 20px;
}

.form-field-label {
  margin-bottom: 8px;
  font-size: 15px;
  font-weight: 500;
  cursor: inherit;
  color: black;
}

.form-field-label-subtitle {
  color: var(--app-text-grey);
  margin-bottom: 8px;
  font-size: 13px;
  font-weight: 400;
}

.form-field-label-title {
  color: black;
  margin-bottom: 8px;
  font-size: 15px;
  font-weight: 500;
}

.form-input {
  border-radius: 5px;
  padding: 15px;
  border: 1px solid var(--app-light-grey);
  width: 100%;
  font-size: 14px;
  background-color: white;
}

.form-input:focus {
  border: 1px solid var(--app-timber);
}

.small-form-input {
  border-radius: 10px;
  padding: 8px;
  border: 1px solid var(--app-light-grey);
  font-size: 14px;
  width: 100px;
}

textarea:focus,
input:focus {
  outline-color: var(--app-timber);
  outline-width: 1px;
}

.mat-form-field-wrapper {
  padding-bottom: 0px !important;
  border: 1px solid var(--app-light-grey);
  border-radius: 5px;
}

.loading-indicator {
  margin: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}

.loading-indicator-container {
  margin: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99999;
  display: flex;
  flex-direction: column; /* Ensures contents are stacked vertically */
  align-items: center; /* Centers contents horizontally */
  justify-content: center; /* Centers contents vertically */
  border-radius: 20px;
  background-color: white;
  padding: 20px; /* Optional padding to create space inside the container */
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.mat-button-toggle-group-appearance-standard {
  border-radius: 30px !important;
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 35px !important;
}

.mat-button-toggle {
  background-color: #fbfbfb;
  color: var(--app-text-grey);
}

.mat-button-toggle:hover {
  background-color: #efefef;
  color: black !important;
}

.mat-button-toggle-checked {
  background-color: white;
  color: black !important;
}

.mat-card-header-text {
  margin-left: 0px !important;
}

.percent-chip {
  background-color: var(--app-wiskey);
  color: white;
  padding: 8px;
  font-size: 10px;
  border-radius: 12px;
}

.mat-menu-content:not(:empty) {
  padding-top: 0px !important;
  padding-bottom: 0px !important;

  padding: 5px !important;
}

.mat-dialog-container {
  border-radius: 10px !important;
}

.mat-select-panel {
  border-radius: 10px !important;
  padding: 10px !important;
}

.mat-option:hover {
  border-radius: 10px !important;
}

.mat-stepper-horizontal,
.mat-stepper-vertical {
  border-radius: 10px !important;
  background: var(--app-container) !important;
  padding-top: 20px;
}

.mat-horizontal-content-container {
  padding: 0px !important;
}

.mat-step-header:hover {
  background: none !important;
}
.mat-step-header.cdk-program-focused {
  background: none !important;
}
.mat-step-header.cdk-mouse-focused {
  background: none !important;
}
.mat-step-header.cdk-focused {
  background: none !important;
}

.mat-step-header .mat-step-icon-selected,
.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
  background-color: var(--app-wiskey) !important;
}

.social-container {
  border-radius: 5px;
  padding: 10px;
  border: 1px solid var(--app-light-grey);
  background-color: white;
  width: 100%;
  font-size: 16px;
  display: flex;
  align-items: center;
}

.mat-tooltip {
  font-size: 14px !important;
}

.mat-snack-bar-container {
  text-align: center;
}

.mat-simple-snackbar {
  display: inline-block !important;
}

.app-color-picker .preview {
  width: 20px !important;
  height: 20px !important;
  background-color: transparent !important;
  background: transparent !important;
  background-image: none !important;
  box-shadow: none !important;
}

.app-color-picker .preview .preview-background {
  background: none !important;
}

.app-color-picker .preview .circle {
  display: none !important;
}

.colour-avatar {
  border-radius: 50%;
  width: 35px;
  height: 35px;
  background-color: #ffe2e2;
  border: 1px solid var(--app-light-grey);
}

.colour-label {
  font-weight: 500;
}

.colour-container {
  display: flex;
  align-items: center;
}

.mat-list-base .mat-list-item .mat-list-item-content,
.mat-list-base .mat-list-option .mat-list-item-content {
  padding: 0px 15px !important;
}

.mat-list-base {
  padding-top: 0px !important;
}

hr {
  background-color: #888888 !important;
}

.emoji-button {
  cursor: pointer;
  background-color: var(--app-timber);
  color: white;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 10px 5px 10px;
  font-size: 14px;
}

.emoji-button:hover {
  background-color: var(--app-wiskey);
}

.status-icon {
  font-size: 20px;
  position: relative;
  top: 3px;
}

#status-chip {
  padding: 5px 15px 5px 15px;
  border-radius: 20px;
  font-weight: 400;
}

.status-chip-unredeemed {
  background-color: #fee8d7;
  color: #ffa13f;
}

.status-chip-redeemed {
  background-color: rgba(145, 219, 145, 0.3);
  color: #5c775c;
}

.status-chip-expired {
  background-color: rgba(208, 151, 134, 0.3);
  color: rgb(202, 47, 47);
}

.search-input {
  border: none;
  padding: 10px;
  width: 100%;
  margin-bottom: 5px;
  border-bottom: 1px solid lightgray;
}

.mat-menu-panel {
  min-height: 58px !important;
}

.crisp-client .cc-1brb6 {
  z-index: 9999999999999 !important;
}

/* .cdk-overlay-container {
  z-index: 9999999999999 !important;
} */

.menu-list-item {
  display: flex;
  align-items: center;
  font-size: 14px !important;
}

.menu-list-item-label {
  font-size: 14px !important;
}

#ngx-colors-overlay {
  z-index: 99999999999 !important;
}

.NgxEditor__Wrapper {
  max-height: 500px;
  overflow-y: auto;
}

.NgxEditor__MenuBar {
  position: sticky;
  top: 0;
  z-index: 1000;
  background: white;
}
